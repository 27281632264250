body {
  font-family: "Barlow", sans-serif;
}
img {
  max-width: 100%;
}
.header {
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  transition: transform 400ms cubic-bezier(0.25, 0.37, 0.17, 0.96);
  z-index: 99999;
}

/* When header is not in viewport we hide it */
.header[data-scroll="out"] {
  transform: translatey(-400%);
}

/* When moving up and header we show it */
[data-scroll-dir-y="-1"] .header {
  transform: translatey(0);
}

.header {
  padding: 0;
  color: #222;
  background: #fff;
  /* box-shadow: 0 6px 8px 0 rgb(0 0 0 / 15%); */
  box-shadow: 0px 2px 6px #00000014;
}

.header_logo {
  font-size: 27px;
  font-weight: 700;
  color: #262250;
}
.nav_parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
}
.header .navbar-nav {
  margin-top: 4px;
}
.header .navbar-brand {
  padding: 0;
}
.hamburger_main.navbar-toggler {
  border: none;
}
.hamburger-react div:nth-child(3) {
  height: 6px;
}
body .offcanvas-backdrop.show {
  opacity: 0;
}
body .offcanvas.offcanvas-end {
  top: 0;
  width: 100%;
  left: -1px;
}

.header_logo img {
  max-width: unset;
  width: 195px;
  max-height: 42px;
}
.navbar-nav {
}
.navbar-nav a {
  color: #242424;
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  margin: 0 4px;
  padding: 0 8px;
  text-decoration: none;
  transition: 0.4s all ease;
}
.navbar-nav a:hover {
  color: #2fc390;
}
.header .navbar-nav .nav-link.active,
.header .navbar-nav .nav-link.show {
  color: inherit;
}
.offcanvas-body {
  text-align: center;
}
.offcanvas-body .navbar-nav a {
}
.get_started {
  background: #2fc390;
  padding: 13px 50px 13px;
  font-size: 26px;
  text-decoration: none;
  font-weight: 400;
  color: #fff;
  transition: 0.4s all ease;
  box-shadow: 0 0 5px 0 #2fc390;
  display: inline-block;
}
.get_started:hover {
  background: #ff5c5c;
  box-shadow: 0 0 30px 0 #ff5c5c;
  padding: 11px 50px 15px;
}
.banner_wrapper {
  background: #f5f5f5;
  padding: 3.5rem 0 0 0;
}
.banner_image {
  text-align: center;
}
.banner_image img {
  max-width: 90%;
}
.banner_content {
}
.banner_content h1 {
  color: #0a346d;
  font-size: 54px;
  font-weight: 700;
  line-height: 125%;
  margin-bottom: 30px;
}
.banner_content h1 span {
  color: #2fc390;
}
.banner_content h6 {
  color: #0a346d;
  font-size: 23px;
  margin-bottom: 30px;
}
.banner_content form {
  margin-bottom: 30px;
}

.banner_content form .form-group label,
.banner_content form .form-group input {
  transition: all 0.2s;
  touch-action: manipulation;
}
.banner_content form .form-group {
  position: relative;
}

.banner_content form .form-group input {
  -webkit-appearance: none;
  border-radius: 0;
  padding: 13px 20px 13px 20px;
  font-size: 17px;
  cursor: text;
  width: 100%;
  background: #fafafa;
  border: 2px solid #ccc;
}
.banner_content form .form-group input:hover {
  border: 2px solid #2fc390;
}
.banner_content form .form-group input:focus {
  outline: 0;
  border: 2px solid #2fc390;
}
.banner_content form .form-group input:placeholder-shown + label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 3.1rem) scale(1);
  margin-left: 18px;
  border-radius: 8px;
  font-size: 18px;
  color: #383838bf;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  left: 0;
  bottom: 64px;
  z-index: 0;
}
.banner_content form .form-group ::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}

.banner_content form .form-group ::-moz-placeholder {
  opacity: 0;
}
/**
  * Show the placeholder when the input is focused.
  */
.banner_content form .form-group input:focus::-webkit-input-placeholder {
  opacity: 0.5;
}

.banner_content form .form-group input:focus::-moz-input-placeholder {
  opacity: 0.3;
}

/**
  * When the element is focused, remove the label transform.
  * Also, do this when the placeholder is _not_ shown, i.e. when 
* there's something in the input at all.
*/
.banner_content form .form-group input:not(:placeholder-shown) {
  border: 2px solid #2fc390;
}

.banner_content form .form-group input:not(:placeholder-shown) + label,
.banner_content form .form-group input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  background: linear-gradient(to top, #fafafa 60%, transparent 40%);
  padding: 0px 4px;
  position: absolute;
  left: 0px;
  bottom: 44px;
  margin-left: 16px;
  border-radius: 8px;
  font-size: 16px;
  color: #2fc390;
  /* color: #1B1B1ECC; */
  font-weight: 500;
  line-height: 24px;
}

.banner_content form .form-group input:not(:placeholder-shown) + label,
.banner_content form .form-group textarea:not(:placeholder-shown) + label {
  background: linear-gradient(to top, #fafafa 60%, transparent 40%);
}
.banner_content input:-webkit-autofill,
.banner_content input:-webkit-autofill:hover,
.banner_content input:-webkit-autofill:focus,
.banner_content input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.banner_content form .form-group input:not(:placeholder-shown) + label,
.banner_content form .form-group input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
}

.banner_content form .form-group .lable_spanbg {
  /* background: #ffffff; */
  padding: 0px 4px;
}

.banner_content form .form-group input:placeholder-shown + label :focus {
  position: absolute;
  left: 0;
}

.banner_content form .form-group input:focus + label {
  color: #2fc390;
}
.banner_content .fields_wrapper {
  margin-bottom: 25px;
}
.banner_content .form_concent {
  color: #0a346d;
  font-size: 16px;
  margin-bottom: 25px;
}
.banner_content .form_concent a {
  color: #2fc390;
}
.form_submit {
  background: #2fc390;
  border: none;
  padding: 13px 50px 13px;
  font-size: 26px;
  text-decoration: none;
  font-weight: 400;
  color: #fff;
  transition: 0.4s all ease;
  box-shadow: 0 0 1px 0 #2fc390;
  display: inline-block;
}
.form_submit:hover {
  background: #ff5c5c;
  box-shadow: 0 0 30px 0 #ff5c5c;
  padding: 11px 50px 14px;
}
.hiw_wrapper {
  padding: 7rem 0 7rem;
  border-bottom: 3px solid #f6f6f6;
}
.hiw_head {
  text-align: center;
  margin-bottom: 80px;
}
.hiw_head h2 {
  color: #0c3d6f;
  font-weight: 600;
  text-align: center;
  font-size: 40px;
}
.hiw_head p {
  font-size: 24px;
}
.hiw_item {
  padding: 20px 30px;
  border: 1px solid #ddd;
  min-height: 270px;
  transition: all 0.4s ease;
  margin: 0 5%;
}
.hiw_item:hover {
  border: 0;
  transform: translateY(-10px);
  box-shadow: 0 4px 8px 3px rgba(188, 206, 215, 0.67);
}
.hiw_item .hiw_item_head {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.hiw_item_head img {
  margin-right: 50px;
}
.hiw_item_head h3 {
  font-size: 24px;
  font-weight: 800;
  max-width: 170px;
  color: #242424;
}
.hiw_item p {
  font-size: 17px;
  font-weight: 500;
  text-align: justify;
}
.cs_wrapper {
  padding: 6rem 0 6rem;
}
.cs_wrapper h2 {
  font-size: 36px;
  color: #0c3d6f;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px;
}
.cs_points {
}
.cs_item {
  display: flex;
  margin-bottom: 50px;
}
.cs_item img {
  max-width: 60px;
  margin-right: 50px;
}
.cs_item h4 {
  line-height: 150%;
  font-size: 22px;
  font-weight: 600;
  color: #292929;
}
.cs_item h4 span {
  display: block;
  color: #2fc390;
}
.cs_points_content {
}
.cs_points_content p {
  font-size: 19px;
  margin-bottom: 28px;
  color: #292929;
}
.cs_points_content p.disclos {
  color: #0a346d;
  margin-bottom: 42px;
}
.home_cta_wrapper {
  background: #15549a;
  color: #fff;
  padding: 4.5rem 0 4.5rem;
}
.home_cta_image {
}
.home_cta_image img {
}
.home_cta_content {
}
.home_cta_content h2 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 25px;
}
.home_cta_content p {
  font-size: 20px;
  max-width: 540px;
  margin-bottom: 30px;
}
.home_cta_content a {
  box-shadow: none;
}
.home_cta_content a:hover {
  box-shadow: none;
}
.page_footer {
  padding: 4.5rem 0 3.5rem;
  background: #f2f2f2;
}
.footer_nav_wrapper {
  text-align: right;
}
.footer_image_div {
  margin-bottom: 15px;
}
.footer_image_div img {
  margin-left: 25px;
}
.footer_nav_wrapper ul {
}
.footer_nav_wrapper ul li {
  display: inline-block;
  padding: 0 10px;
  position: relative;
  font-size: 18px;
}
.footer_nav_wrapper ul li:after {
  content: "|";
  position: absolute;
  right: 0;
  top: 0;
}
.footer_nav_wrapper ul li:last-child:after {
  content: "";
}
.footer_nav_wrapper ul li a {
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  color: #292929;
}
.footer_nav_wrapper ul li a:hover {
  color: #2fc390;
}
.top_footer {
  margin-bottom: 5px;
}
.footer_text {
}
.footer_text p {
  margin-bottom: 15px;
  font-size: 14px;
}
.footer_copyright_text {
  text-align: center;
}
.footer_copyright_text p {
  font-size: 16px;
}
@media screen and (max-width: 1500px) {
  .banner_wrapper .container {
    max-width: 100%;
    padding: 0 3%;
  }
}
@media only screen and (max-width: 991px) {
  .offcanvas-body {
    padding: 10rem 0px 50px 0px;
    display: flex;
    align-items: flex-start;
    background: #fff;
  }
  .navbar-nav a {
    font-size: 30px;
    margin-bottom: 40px;
    font-weight: 400;
  }
  .hamburger_main {
    all: unset;
  }
  .top_banner_row {
    flex-flow: column-reverse;
  }
  .banner_content h6 {
    margin-bottom: 0;
  }
  .banner_content form .form-group {
    margin-top: 25px;
  }
  .hiw_item {
    margin-bottom: 30px;
  }
  .home_cta_wrapper {
    text-align: center;
    padding: 3rem 0 3rem;
  }
  .home_cta_image img {
    margin-bottom: 20px;
    max-width: 80%;
  }
  .home_cta_content p {
    max-width: 100%;
  }
  .home_cta_content h2 {
    font-size: 32px;
  }
  .footer_logo_wrapper {
    margin-bottom: 25px;
    text-align: center;
  }
  .footer_image_div {
    margin-bottom: 25px;
  }

  .footer_nav_wrapper ul {
    padding-left: 0;
  }
  .footer_nav_wrapper ul li {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .footer_nav_wrapper {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .hiw_wrapper {
    padding: 3rem 0 3rem;
  }
  .hiw_head {
    margin-bottom: 40px;
  }
  .hiw_item {
    min-height: auto;
  }

  .page_footer {
    text-align: center;
    padding: 3rem 0 3rem;
  }
}
@media screen and (max-width: 576px) {
  .banner_wrapper {
    text-align: center;
  }

  .banner_content h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .banner_content h6 {
    font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  .banner_content h1 {
    font-size: 32px;
  }
  .hiw_head h2 {
    font-size: 32px;
  }
  .hiw_head p {
    font-size: 20px;
  }
  .hiw_item_head img {
    margin-right: 30px;
  }
  .hiw_item_head h3 {
    font-size: 20px;
  }
  .cs_wrapper h2 {
    font-size: 32px;
  }
  .cs_points_content p {
    font-size: 17px;
  }
}
@media screen and (max-width: 400px) {
}
